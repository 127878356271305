import { apiAxios } from './customAxios';


interface GetMakesParams {
    [key: string]: any;
}

export const getMakes = async (params: GetMakesParams) => {
    const {...otherParams } = params;

    const data = {
        ...otherParams
    }
    
    try {
        const response = await apiAxios.post('/get_makes', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data
    } catch (error) {
        console.error('Error posting data:', error);
    }
};



export const getModels = async (params: GetMakesParams) => {
    const {...otherParams } = params;

    const data = {
        ...otherParams
    }
    
    try {
        const response = await apiAxios.post('/get_models', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data
    } catch (error) {
        console.error('Error posting data:', error);
    }
};

export const getWebsites = async (params: GetMakesParams) => {
    const {...otherParams } = params;

    const data = {
        ...otherParams
    }
    
    try {
        const response = await apiAxios.post('/get_websites', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data
    } catch (error) {
        console.error('Error posting data:', error);
    }
};



