// carCards.tsx
import React, {useState} from 'react';
import './CarCards.scss';
import Countdown from '../Countdown';
import { Pagination } from 'antd'

interface CarCardProps {
    page: number;
    itemsPerPage: number;
    dataSource: Array<{
        key: string;
        name: string;
        currentBid: string;
        timeLeft: string; // Unix timestamp for countdown
        location: string;
        website: string;
        image: string;
        link: string;
    }>;
    carsLength: number;
    onPageChange: (page: number) => void;
}

const CarCards: React.FC<CarCardProps> = ({ page,itemsPerPage, dataSource, carsLength, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(page);

    const handleTableChange = (pagination: any) => {
        setCurrentPage(pagination);
        onPageChange(pagination); // Notify parent component of page change
    };

    const handleViewClick = (link: string) => {
        window.open(link, '_blank'); // Opens link in a new tab
    };

    return (
        <div className="car-cards-container">
            {dataSource.map((car) => (
                <div className="car-card" key={car.key}>
                    <div className="image-container">
                        <img src={car.image} alt={car.name} />
                        <div className="time-left"><Countdown unixTimestamp={car.timeLeft}/></div>
                    </div>
                    <div className="car-details">
                        <h3 className="car-name">{car.name}</h3>
                        <p className="car-price">{car.currentBid}</p>
                        <p className="car-location">Location: {car.location}</p>
                        <button
                            className="view-button"
                            onClick={() => handleViewClick(car.link)} // Pass link to handler
                        >
                            View {car.website}
                        </button>
                    </div>
                </div>
            ))}
            <Pagination
                current={page}
                pageSize={itemsPerPage}
                total={carsLength}
                onChange={handleTableChange}
                showSizeChanger={false}/>
        </div>
    );
};

export default CarCards;
