// src/utils/authUtils.ts
import { auth } from './firebase';
import { signOut } from 'firebase/auth';

export const signout = async (): Promise<void> => {
  try {
    await signOut(auth);
    console.log("User successfully signed out");
  } catch (error) {
    console.error("Error signing out:", error);
  }
};
