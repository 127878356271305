import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Row } from 'antd';

interface FilterMakesProps {
    filterMakes: string[];
    selectedMakes: string[];
    onChange: (id: string, value: string[]) => void;
}

export const FilterMakes: React.FC<FilterMakesProps> = ({ filterMakes, selectedMakes, onChange }) => {
    const [checkedValues, setCheckedValues] = useState<string[]>(selectedMakes || []);

    useEffect(() => {
        setCheckedValues(selectedMakes || []);
    }, [selectedMakes]);

    const handleChange = (checkedValues: string[]) => {
        setCheckedValues(checkedValues);
        onChange('selectedMakes', checkedValues);
    };

    

    return (
        <Checkbox.Group style={{ width: '100%' }} value={checkedValues} onChange={handleChange}>
            <Col>
                {filterMakes && filterMakes.length > 0 && filterMakes.map((make) => (
                    <Row key={make} style={{ marginBottom: '10px' }}>
                        <Checkbox value={make}>{make}</Checkbox>
                    </Row>
                ))}
            </Col>
        </Checkbox.Group>
    );
};

export default FilterMakes;
  