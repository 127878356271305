import React, {useState, useEffect} from 'react';
import { Collapse, Button } from 'antd';
import { FilterYear } from './filterYear/FilterYear';
import { FilterMakes } from './filterMakes/FilterMakes';
import { FilterLocation } from './filterLocation/FilterLocation';
import { FilterWebsite } from './filterWebsite/FilterWebsite';
import { FilterModels } from './filterModels/FilterModels';
import { FilterValues, defaultFilterValues } from '../../types/home';

import { getMakes, getModels, getWebsites } from '../../utils/getFilters';

import './FilterOptions.scss';

const { Panel } = Collapse;

interface FilterOptionsProps {
    filterValues: any;
    setFilterValues: React.Dispatch<React.SetStateAction<any>>;
    onFilterValuesChange: (filterValues: any) => void;
}

const FilterOptions: React.FC<FilterOptionsProps> = ({ filterValues, setFilterValues, onFilterValuesChange }) => {
    const [makes, setMakes] = useState<string[]>([]);
    const [models, setModels] = useState<string[]>([]);
    const [websites, setWebsites] = useState<string[]>([]);

    const handleFilterChange = (updates: { [key: string]: string | string[] }) => {
        setFilterValues((prevValues: FilterValues) => ({ 
            ...prevValues, 
            ...updates 
        }));
    };

    //dynamically update filters
    //makes
    useEffect(() => {
        const fetchMakes = async () => {
            const params = {
                ...filterValues, 
            };
            const data = await getMakes(params);
            setMakes(data);
            
        };
        fetchMakes();
    }, [filterValues.selectedModels, filterValues.websites, //filterValues.endYear, filterValues.startYear
]);

    //models
    useEffect(() => {
        const fetchModels = async () => {
            const params = {
                ...filterValues, 
            };
            const data = await getModels(params);
            setModels(data);
            
        };
        fetchModels();
    }, [filterValues.selectedMakes, filterValues.websites, 
        //filterValues.endYear, filterValues.startYear
    ]);

    //auction sites
    useEffect(() => {
        const fetchSites = async () => {
            const params = {
                ...filterValues, 
            };
            const data = await getWebsites(params);
            setWebsites(data);
            
        };
        fetchSites();
    }, [filterValues.selectedMakes, filterValues.selectedModels, 
     //   filterValues.endYear, filterValues.startYear
    ]);

    useEffect(() => {
        onFilterValuesChange(filterValues);
    }, [filterValues, onFilterValuesChange]);
    
    
    const clearAllFilters = () => {
        setFilterValues(defaultFilterValues);
    };

    return (
        <div className="filter-options">
            <Collapse>
                <Panel header="Filter by Make" key="1">
                    <FilterMakes 
                        filterMakes={makes}
                        selectedMakes={filterValues.selectedMakes}
                        onChange={(id, value) => handleFilterChange({ [id]: value })}/>
                </Panel>
                <Panel header="Filter by Model (Beta)" key="2">
                    <FilterModels
                        filterModels={models}
                        selectedModels={filterValues.selectedModels}
                       onChange={(id, value) => handleFilterChange({ [id]: value })}/>
                </Panel>
                <Panel header="Filter by Year" key="3">
                    <FilterYear 
                        startYear={filterValues.startYear} 
                        endYear={filterValues.endYear} 
                        onChange={(id, value) => handleFilterChange({ [id]: value })}/>
                </Panel>
                <Panel header="Filter by Location" key="4">
                    <FilterLocation
                        location={filterValues.location}
                        distance={filterValues.distance}
                        onChange={handleFilterChange}
                    />
                </Panel>
                <Panel header="Filter by Website" key="5">
                    <FilterWebsite 
                        auction_sites={websites}
                        websites={filterValues.websites}
                        onChange={(id, value) => handleFilterChange({ [id]: value })}
                    />
                </Panel>
            </Collapse>
            <Button type="primary" className="clear-filters" onClick={clearAllFilters}>Clear All Filters</Button>
        </div>
    );
};

export { FilterOptions };