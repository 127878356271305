import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from "./views/Home";
import Signin from "./views/Signin";
import Signup from "./views/Signup";
import { UserProvider } from "./context/UserContext";
import Account from "../src/components/Account/Account";
import { sendPageView } from "./utils/analytics";

function App() {
  useEffect(() => {
    // Send page view when the route changes
    sendPageView('/scout')
  }, [])
  return (

    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/account" element={<Account />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
