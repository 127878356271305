import React, {useState} from 'react';
import { Table, Button } from 'antd';
import Countdown from '../Countdown';
import './CarTable.scss';
import logo from "../../assets/logo-white.png";

interface CarTableProps {
    page: number;
    itemsPerPage: number;
    dataSource: Array<{
        key: string;
        name: string;
        currentBid: string;
        timeLeft: string; // Unix timestamp for countdown
        location: string;
        website: string;
        image: string;
        link: string;
    }>;
    carsLength: number;
    onPageChange: (page: number) => void;
    onSortChange: (sortField: string, sortOrder: string) => void; // Add sorting handler

}

const CarTable: React.FC<CarTableProps> = ({ page,itemsPerPage, dataSource, carsLength, onPageChange, onSortChange }) => {
    const [currentPage, setCurrentPage] = useState(page);

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setCurrentPage(pagination.current);
        onPageChange(pagination.current); // Notify parent component of page change
        if (sorter.order) {
            onSortChange(sorter.field, sorter.order); // Notify parent component of sort change
        } else {
            onSortChange('', ''); // Reset sorting if no order
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (text: string, record: any) => (
                <div className="table-name-container">
                    <img 
                        src={record.image} 
                        alt={text} 
                        className="table-image" 
                        onError={(e) => { e.currentTarget.src = logo; }} // Optional fallback image
                    />
                    <span className="table-name">{text}</span>
                </div>
            ),
        },
        {
            title: 'Current Bid',
            dataIndex: 'currentBid',
            key: 'currentBid',
            sorter: true,
            render: (text: string) => <span className="table-bid">{text}</span>,
        },
        {
            title: 'Time Left',
            dataIndex: 'timeLeft',
            key: 'timeLeft',
            sorter: true,
            render: (timeLeft: string) => <Countdown unixTimestamp={timeLeft} />, // Use Countdown component
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            sorter: true,
            render: (text: string) => <span className="table-location">{text}</span>,
        }, 
        {
            title: 'Website',
            dataIndex: 'website',
            key: 'website',
            sorter: true,
            render: (text: string) => <span className="table-website">{text}</span>,
        },
    ];

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ pageSize: itemsPerPage, current: page, total: carsLength, showSizeChanger: false  }}
            className="car-table"
            onChange={handleTableChange}
            onRow={(record) => ({
                onClick: () => {
                    window.open(record.link, '_blank');
                },
                style: { cursor: 'pointer' },
            })}
        />
    );
};

export { CarTable };
