import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Button, Select, Spin } from "antd";
import { MenuOutlined, FilterOutlined } from "@ant-design/icons";
import { searchCars } from '../utils/searchCars';
import { SearchBar } from '../components/SearchBar/SearchBar';
import { FilterOptions } from '../components/FilterOptions/FilterOptions';
import { CarTable } from '../components/CarTable/CarTable';
import CarCards from '../components/CarCards/CarCards';
import TopBar from '../components/TopBar/TopBar';
import './Home.scss';
import { CarCardProps, FilterValues, defaultFilterValues } from '../types/home';
import { useNavigate } from "react-router-dom";
import { auth } from "../utils/firebase";

const { Option } = Select;

const Home: React.FC = () => {
    const [userVar, setUser] = useState<any>(null);
    const navigate = useNavigate();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isFilterCollapsed, setIsFilterCollapsed] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCars, setFilteredCars] = useState<CarCardProps[]>([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [cars, setCars] = useState([]);
    const [filterValues, setFilterValues] = useState<FilterValues>(defaultFilterValues);
    const [loading, setLoading] = useState(false);
     // used to calculate the number of pages to show in the table and cards
     const [carsLength, setCarsLength] = useState(0);
     // sort field and order state
     const [sortField, setSortField] = useState('');
     const [sortOrder, setSortOrder] = useState('');

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            if (!user) {
                navigate("/signin");
            }
        });
        return unsubscribe;
    }, [navigate]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
      if (cars) {
        const formattedData = cars.map((car: any, index: number) => ({
          key: car.Link || index.toString(),
          name: car.Name,
          currentBid:
            car.Price === -1 ? "No bids" : `$${car.Price.toLocaleString()}`,
          timeLeft: car.EndTime,
          location: car.Location,
          website: car.Website,
          image: car.imageLink,
          link: car.Link,
        }));
  
        setFilteredCars(formattedData);
      }
    }, [cars]);

    const handleItemsPerPageChange = (value: number) => {
        setItemsPerPage(value);
    };

    const handleSearchTermChange = (term: string) => {
        setSearchTerm(term);
    };

    const handleFilterValuesChange = (newFilterValues: any) => {
        setFilterValues(newFilterValues);
    };

    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    };
    const handlePageChange = (newPage: number) => {
      setPage(newPage);
  };
  
  const handleSortChange = (field: string, order: string) => {
      setSortField(field);
      setSortOrder(order);
  };
  
    // Helper function to format time left
    const formatTimeLeft = (endTime: number): string => {
      const timeDiff = endTime * 1000 - Date.now();
      if (timeDiff <= 0) return "Ending Soon";
  
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    };
  
    // Fetch data from the server when the page, itemsPerPage, searchTerm, or filter info changes
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        const params = {
          page,
          itemsPerPage,
          searchTerm,
          sortField,
          sortOrder,
          ...filterValues,
        };
        const data = await searchCars(params);
        setLoading(false);
        setCars(data.cars);
        setCarsLength(data.carsLength);
      };
  
      fetchData();
    }, [page, itemsPerPage, searchTerm, filterValues, sortOrder]);
  
    // format car data to match table output and sort by time left
    useEffect(() => {
      if (cars) {
        const formattedData = cars.map((car: any, index: number) => ({
          key: car.Link || index.toString(),
          name: car.Name,
          currentBid:
            car.Price === -1 ? "No bids" : `$${car.Price.toLocaleString()}`,
          timeLeft: car.EndTime,
          location: car.Location,
          website: car.Website,
          image: car.imageLink,
          link: car.Link,
        }));
  
        setFilteredCars(formattedData);
      }
    }, [cars]);
  
    // Effect to listen to window resize events and update `isMobile` state
    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 768);
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return (
      <div className="home-container">
          <TopBar title="Live Auctions" user={userVar} />
  
          <div className="main-content">
              {/* Hamburger Menu Button for mobile */}
              {isMobile && (
                  <Button
                      className="hamburger-menu"
                      icon={<FilterOutlined />}
                      onClick={showDrawer}
                  />
              )}
  
              {/* Filter Sidebar - Drawer for mobile, inline for desktop */}
              {isMobile ? (
                  <Drawer
                      title="Filters"
                      placement="left"
                      onClose={closeDrawer}
                      visible={isDrawerVisible}
                      width={250}
                  >
                      <SearchBar onSearchTermChange={handleSearchTermChange} />
                      <FilterOptions 
                          filterValues={filterValues}
                          setFilterValues={setFilterValues}
                          onFilterValuesChange={handleFilterValuesChange}
                      />
                      <div className="items-per-page">
                          <span>Items per page:</span>
                          <Select defaultValue={10} onChange={handleItemsPerPageChange} style={{ width: '100%' }}>
                              <Option value={10}>10</Option>
                              <Option value={20}>20</Option>
                              <Option value={50}>50</Option>
                          </Select>
                      </div>
                      <Button type="primary" onClick={closeDrawer} style={{ marginTop: '1rem', width: '100%' }}>
                          Apply Filters
                      </Button>
                  </Drawer>
              ) : (
                  <div className={`filter-sidebar ${isFilterCollapsed ? 'collapsed' : ''}`}>
                      <SearchBar onSearchTermChange={handleSearchTermChange} />
                      <FilterOptions 
                          filterValues={filterValues}
                          setFilterValues={setFilterValues}
                          onFilterValuesChange={handleFilterValuesChange}
                      />
                      <div className="items-per-page">
                          <span>Items per page:</span>
                          <Select defaultValue={10} onChange={handleItemsPerPageChange}>
                              <Option value={10}>10</Option>
                              <Option value={20}>20</Option>
                              <Option value={50}>50</Option>
                          </Select>
                      </div>
  
                      {/* Show Hide Filters button only on desktop */}
                      {/* {!isMobile && (
                          <button 
                              className="collapse-button"
                              onClick={() => setIsFilterCollapsed(!isFilterCollapsed)}
                          >
                              {isFilterCollapsed ? 'Show Filters' : 'Hide Filters'}
                          </button>
                      )} */}
                  </div>
              )}
  
              {/* Main Table Content */}
              <div className="table-container">
                <Spin spinning={loading} size="large">
                {isMobile ? (
                    <CarCards 
                        page={page} 
                        itemsPerPage={itemsPerPage} 
                        dataSource={filteredCars}
                        carsLength={carsLength}
                        onPageChange={handlePageChange} />
                ) : (
                    <CarTable 
                        page={page} 
                        itemsPerPage={itemsPerPage} 
                        dataSource={filteredCars}
                        carsLength={carsLength}
                        onPageChange={handlePageChange}
                        onSortChange={handleSortChange} />
                )}
                </Spin>
            </div>
          </div>
      </div>
  );
  
};

export default Home;