// src/pages/Account.tsx
import React , { useEffect } from "react";
import { Button } from "antd";
import { signout } from "../../utils/Signout";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import TopBar from "../TopBar/TopBar"; // Import the TopBar component
import "./Account.scss"; // Add any necessary styles for this page
import profile from "../../assets/Profile.png";

const Account: React.FC = () => {
  const navigate = useNavigate();
  const { email } = useUser(); // Access the email from context

  const handleLogout = async () => {
    await signout();
    navigate("/signin"); 
  };

  useEffect(() => {
    console.log("User email in Account component:", email);
  }, [email]);

  if (!email) {
    // Show a loading message until the email is available
    return <div className="account-page">
    <TopBar title="Account" user={true} />
    <div className="account-content">
      <h1>Account</h1>
      <p>All About You</p>
      <div className="profile-picture">
          <img
              src={profile} 
              alt="Profile"
              className="profile-img"
          />
      </div>
      <p className="email">dealer@example.com</p>
      <div className="button-group">
        <Button type="primary" className="account-button" onClick={handleLogout}>
          Log Out
        </Button>
        <Button type="primary" className="account-button">
          Profile
        </Button>
      </div>
      <div className="tabs">
        <span className="tab active">History</span>
        <span className="tab">Pending</span>
      </div>
      <div className="transaction">
        <h2>Create A Transaction</h2>
        <p>To start building your history</p>
      </div>
    </div>
  </div>;
  }

  return (
    <div className="account-page">
      <TopBar title="Account" user={true} />
      <div className="account-content">
        <h1>Account</h1>
        <p>All About You</p>
        <div className="profile-picture">
            <img
                src={profile} 
                alt="Profile"
                className="profile-img"
            />
        </div>
        <p className="email">{email}</p>
        <div className="button-group">
          <Button type="primary" className="account-button" onClick={handleLogout}>
            Log Out
          </Button>
          <Button type="primary" className="account-button">
            Profile
          </Button>
        </div>
        <div className="tabs">
          <span className="tab active">History</span>
          <span className="tab">Pending</span>
        </div>
        <div className="transaction">
          <h2>Create A Transaction</h2>
          <p>To start building your history</p>
        </div>
      </div>
    </div>
  );
};

export default Account;
