import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Row } from 'antd';

interface FilterModelsProps {
    filterModels: string[];
    selectedModels: string[];
    onChange: (id: string, value: string[]) => void;
}

export const FilterModels: React.FC<FilterModelsProps> = ({ filterModels, selectedModels, onChange }) => {
    const [checkedValues, setCheckedValues] = useState<string[]>(selectedModels || []);

    useEffect(() => {
        setCheckedValues(selectedModels || []);
    }, [selectedModels]);

    const handleChange = (checkedValues: string[]) => {
        setCheckedValues(checkedValues);
        onChange('selectedModels', checkedValues);
    };

    return (
        <Checkbox.Group style={{ width: '100%' }} value={checkedValues} onChange={handleChange}>
            <Col>
                {filterModels && filterModels.length > 0 && filterModels.map((model) => (
                    <Row key={model} style={{ marginBottom: '10px' }}>
                        <Checkbox value={model}>{model}</Checkbox>
                    </Row>
                ))}
            </Col>
        </Checkbox.Group>
    );
};

export default FilterModels;
  