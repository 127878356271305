// Countdown.tsx
import React, { useEffect, useState } from 'react';

interface CountdownProps {
  unixTimestamp: number | string;
}

const Countdown: React.FC<CountdownProps> = ({ unixTimestamp }) => {
  // Convert unixTimestamp to a number before any calculations
  const targetTime = Number(unixTimestamp);
  const [timeLeft, setTimeLeft] = useState<number>(targetTime - Math.floor(Date.now() / 1000));

  useEffect(() => {
    const updateCountdown = () => {
      const now = Math.floor(Date.now() / 1000); // Current time in Unix
      const newTimeLeft = targetTime - now;
      setTimeLeft(newTimeLeft);
    };

    // Update the countdown every second
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [targetTime]);

  // Render countdown based on time left
  if (timeLeft > 500) {
    const days = Math.floor(timeLeft / 86400);
    const hours = Math.floor((timeLeft % 86400) / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    const output: string[] = [];
    if (days > 0) output.push(`${days} day${days !== 1 ? "s" : ""}`);
    if (hours > 0) output.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
    if (minutes > 0) output.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
    if (seconds >= 0) output.push(`${seconds} second${seconds !== 1 ? "s" : ""}`);

    return <span>{output.join(", ")}</span>;
  } else if (timeLeft > -300) {
    return <span>Ending Soon</span>;
  } else {
    return <span>Time's up!</span>;
  }
};

export default Countdown;
